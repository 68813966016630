import * as apis from '@/api/land';
import { copyToClipboard } from "@/utils";
export default {
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    uid: {
      type: [String, Number]
    },
    link_id: {
      type: [String, Number]
    },
    tid: {
      type: [String, Number]
    }
  },
  data() {
    return {
      // 控制对话框显示隐藏
      show: false,
      // 弹窗类型
      dialogType: "copy"
    };
  },
  mounted() {
    if (this.content.movement === 3) {
      this.dialogType = "cardPost";
      let pressTimer;
      const that = this;
      const postCard = document.getElementById("postCard");
      // 绑定长按事件
      postCard.addEventListener("touchstart", function (e) {
        pressTimer = setTimeout(function () {
          // 在这里添加二维码识别的逻辑
          that.show = true;
        }, 500); // 长按时间设定为1秒
      });

      // 清除计时器，防止误触发
      postCard.addEventListener("touchend", function () {
        clearTimeout(pressTimer);
      });

      // 防止页面滚动时触发长按事件
      postCard.addEventListener("touchmove", function (e) {
        e.preventDefault();
      });
    }
    if (this.content.movement === 2) {
      this.$nextTick(() => {
        let pressTimer2;
        // const that = this;
        const copyContentWx = document.getElementById("copyContentWx");
        // 绑定长按事件
        copyContentWx.addEventListener("touchstart", function (e) {
          pressTimer2 = setTimeout(function () {
            // 在这里添加二维码识别的逻辑
            copyToClipboard(copyContentWx.innerText, '');
          }, 500); // 长按时间设定为1秒
        });

        // 清除计时器，防止误触发
        copyContentWx.addEventListener("touchend", function () {
          clearTimeout(pressTimer2);
        });

        // 防止页面滚动时触发长按事件
        copyContentWx.addEventListener("touchmove", function (e) {
          e.preventDefault();
        });
      });
    }
  },
  methods: {
    async handlePostCard() {
      // 直接唤起客服名片页
      if (this.content.movement === 1) {
        // 直接跳转客服名片页
        if (this.content.jumpWay === 1) {
          // 调用接口直接跳到微信客服页面
          await this.featchScheme();
        } else if (this.content.jumpWay === 2) {
          this.show = true;
          this.dialogType = "cardPost";
        }
      } else if (this.content.movement === 2) {
        // 复制唤起客服名片页
        this.dialogType = "copy";
        this.show = true;
      } else if (this.content.movement === 3) {
        // 长按唤起
      }
    },
    handleClose() {
      this.show = false;
    },
    async featchScheme() {
      apis.acquisitionScheme({
        uid: this.uid,
        tid: this.tid,
        wechat_package_id: this.content.wechat_package_id
      }).then(res => {
        console.log(res.data);
        if (res.code == '200' && typeof res.data.ab_limit != 'undefined' && res.data.ab_limit == 1) {
          this.$emit('blackShow');
          return;
        }
        window.location.href = decodeURIComponent(res.data.acquisition_url);
      });
    }
  }
};