import * as apis from "@/api/land";
import { $emit } from "@/utils/gogocodeTransfer";
export default {
  name: "obtainingCustomers",
  props: {
    content: Object,
    tid: String,
    dia: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  },
  async created() {},
  methods: {
    obtainingCustomersView() {
      $emit(this, 'jumpCustomerAcquisition', this.content);
    }
  }
};