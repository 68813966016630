import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["obtainingCustomers", [$props.content.positionTopBottom == 'top' ? 'obtainingCustomers' : ' bottomPosition']])
  }, [_createElementVNode("div", {
    class: "obtainingCustomersBox",
    style: _normalizeStyle({
      backgroundColor: $props.content.backgroundColor
    })
  }, [_createElementVNode("div", {
    class: "btn",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.obtainingCustomersView && $options.obtainingCustomersView(...args)),
    style: _normalizeStyle({
      width: $props.content.btnWidth,
      height: $props.content.btnHeight + 'px',
      backgroundColor: $props.content.buttonColor,
      borderRadius: $props.content.buttonRadius + 'px'
    })
  }, [_createElementVNode("div", {
    style: _normalizeStyle({
      fontSize: $props.content.buttonTextSize + 'px',
      color: $props.content.buttomTextColor,
      fontWeight: $props.content.buttonTextFontWeight
    })
  }, _toDisplayString($props.content.buttonText), 5), _createElementVNode("div", {
    style: _normalizeStyle({
      marginLeft: $props.content.textMarginLeft + 'px',
      fontSize: $props.content.buttonTextSizeRight + 'px',
      color: $props.content.buttomTextColorRight,
      fontWeight: $props.content.buttonTextFontWeightRight
    })
  }, _toDisplayString($props.content.buttonTextRight), 5)], 4)], 4)], 2);
}