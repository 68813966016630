import axios from 'axios'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000, // request timeout
})
const postRequests = []
const getRequests = []
// request interceptor
service.interceptors.request.use(
  (config) => {
    // console.log(config)
    if (config.method.toUpperCase() === 'POST') {
      postRequests.push(config.url)
    }
    if (config.method.toUpperCase() === 'GET') {
      const get = {
        url: config.url,
        params: JSON.stringify(config.params),
        status: true,
      }
      if (getRequests.some((r) => r.url === config.url)) {
        getRequests.forEach((r, i) => {
          if (r.url === config.url && r.status === true) {
            getRequests[i].status = false
          }
        })
      }
      getRequests.push(get)
    }

    let biz = ''
    const url = window.location.href.split('?')[1]
    if (url.indexOf('&') === '-1') {
      if (url.split('=')[0] === 'biz') {
        biz = url.split('=')[1]
      }
    } else {
      const arrs = url.split('&')
      for (let i = 0; i < arrs.length; i++) {
        const name = arrs[i].split('=')[0]
        switch (name) {
          case 'biz':
            biz = arrs[i].split('=')[1]
            break
        }
      }
    }
    config.url+='?biz='+biz
    config.headers['biz'] = biz
    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  async (response) => {
    const res = response.data

    if (res.code !== 200) {

    } else {
      return res
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
