import * as Vue from 'vue'
import App from './App.vue'
import router from './router'
window.$vueApp = Vue.createApp(App)
//引入移动适配方法
import './rem'

//引入vue-finger-directive
import Directive from 'vue-finger-directive'
window.$vueApp.use(Directive)


// 引入字体图标
import 'iconfont/iconfont.css'
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
window.$vueApp.use(ElementPlus)

//引入轮播图
import VueAwesomeSwiper from 'vue-awesome-swiper'
window.$vueApp.use(VueAwesomeSwiper)
import '@/styles/quill.core.css'
// axios - 请求接口
import axios from 'axios'
window.$vueApp.config.globalProperties.axios = axios
window.$vueApp.use(router)

//路由拦截器
router.beforeEach((to, from, next) => {
  next()
})

window.$vueApp.config.globalProperties.routerAppend = (path, pathToAppend) => {
  return path + (path.endsWith('/') ? '' : '/') + pathToAppend
}
window.$vueApp.mount('#app')
