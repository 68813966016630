export  function copyToClipboard(text, tip) {
  // 创建一个文本区域元素
  var textArea = document.createElement("textarea");
  
  // 设置文本区域的值为要复制的文本
  textArea.value = text; 
  
  // 将文本区域添加到文档中
  document.body.appendChild(textArea);
  
  // 选中文本
  textArea.select();
  
  // 执行复制命令
  document.execCommand('copy');
  
  // 移除文本区域元素
  document.body.removeChild(textArea);

  alert(`复制成功${tip}`);
}

// 十六进制颜色码转换成RGB颜色值
export function hexToRgb(hex) {
  // 去除可能包含的 # 符号
  hex = hex.replace(/^#/, '');

  // 如果是缩写形式的十六进制颜色码，进行展开
  if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // 将十六进制转换为十进制
  var bigint = parseInt(hex, 16);

  // 分离RGB通道
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  // 返回RGB颜色值
  return {r: r, g: g, b: b};
}
