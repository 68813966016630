import { $on, $off, $once, $emit } from '../../../utils/gogocodeTransfer';
import * as Vue from 'vue';
import * as apis from '@/api/land';
export default {
  props: ['content', 'hrefs', 'tid', 'text', 'ibrand_id'],
  data() {
    return {
      width: '',
      height: '',
      clleft: '',
      cltop: '',
      popimgs: ''
    };
  },
  mounted() {
    let clientWidth = document.documentElement.clientWidth;
    let clientHeight = document.documentElement.clientHeight;
    if (clientWidth > 800) clientWidth = 800;
    const wsize = (clientWidth / 375).toFixed(2);
    this.width = wsize * this.content.w;
    this.height = wsize * this.content.h;
    this.clleft = wsize * this.content.x;
    if (this.content.isBottom) {
      this.cltop = `calc(100% - ${this.height}px)`;
    } else {
      this.cltop = this.content.y + 'px';
    }
    if (this.text === '品牌logo' && this.ibrand_id != 0) {
      apis.getVrandLogo({
        brand_id: this.ibrand_id
      }).then(res => {
        console.log(res);
        this.popimgs = res.data.brand_logo_url.image;
      });
    }
  },
  methods: {
    openpage(row) {
      //linkType 0 整图调整  1单图调整 
      if (this.content.isclick) {
        if (this.content.linkType === 0) {
          if (this.content.wholeType === 2) {
            $emit(this, 'openWechat', this.content.appletlink, this.content.appletUrl, this.content.wholeApplet, this.content.wholeAppletType);

            // if (this.content.appletlink == 0) {
            //   $emit(
            //     this,
            //     'openWechat',
            //     this.content.wholeApplet,
            //     this.content.wholeAppletType
            //   )
            // } else {
            //   window.location.href = this.content.appletUrl
            // }
          } else if (this.content.wholeType === 0) {
            let id = 'new_a';
            let a = document.createElement('a');
            let url = `${this.content.webpageUrl}${this.content.webpageUrl.indexOf('?') > -1 ? '&' : '?'}tid=${this.tid}`;
            a.setAttribute('href', url);
            // a.setAttribute('target', '_blank')
            a.setAttribute('id', id);
            // 防止反复添加
            if (!document.getElementById(id)) {
              document.body.appendChild(a);
            }
            a.click();
          } else if (this.content.wholeType === 4) {
            $emit(this, 'jumpKf', this.content.wechatKf);
          } else if (this.content.wholeType === 6) {
            $emit(this, 'jumpCustomerAcquisition', this.content);
          } else if (this.content.wholeType === 7) {
            ///打开弹窗
            if (this.content.wholePopup != '') $emit(this, 'arousePop', this.content.wholePopup);
          }
        } else if (this.content.linkType === 2) {
          window.location.href = `tel:${this.content.telephone}`;
        }
      }
    }
  },
  emits: ['openWechat', 'jumpKf', 'jumpCustomerAcquisition']
};