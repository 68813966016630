import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-64f7b584"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "fixed"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = ["id"];
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  key: 1
};
const _hoisted_7 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.imgUrl == '' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$props.content.kefuimg && $data.img ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    style: {
      "font-size": "0"
    },
    id: 'qrcodeAssembly' + $props.index
  }, [_createElementVNode("img", {
    class: "img_wh iosImg",
    src: $props.content.kefuimg,
    onTouchstart: _cache[0] || (_cache[0] = $event => $options.touchStart1($event)),
    onTouchend: _cache[1] || (_cache[1] = (...args) => _ctx.touchend2 && _ctx.touchend2(...args))
  }, null, 40, _hoisted_4), _createElementVNode("div", {
    class: "relative",
    style: _normalizeStyle({
      left: $data.clleft + 'px',
      top: $data.cltop + 'px'
    }),
    onTouchstart: _cache[2] || (_cache[2] = $event => $options.touchStart($event)),
    onTouchend: _cache[3] || (_cache[3] = (...args) => $options.touchend && $options.touchend(...args))
  }, [_createElementVNode("img", {
    class: "qr-code",
    style: _normalizeStyle({
      width: $data.imgsize + 'px'
    }),
    src: $data.img
  }, null, 12, _hoisted_5)], 36)], 8, _hoisted_3)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), $data.imgUrl != '' ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("img", {
    class: "img_wh",
    src: $data.imgUrl,
    onTouchstart: _cache[4] || (_cache[4] = $event => $options.touchStart($event)),
    onTouchend: _cache[5] || (_cache[5] = (...args) => $options.touchend && $options.touchend(...args))
  }, null, 40, _hoisted_7)])) : _createCommentVNode("", true)]);
}