import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-66698f94"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  class: "tc"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "close"
};
const _hoisted_5 = {
  class: "title title1"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  style: {
    "display": "flex",
    "margin-bottom": "0.5rem"
  }
};
const _hoisted_8 = {
  style: {}
};
const _hoisted_9 = {
  key: 0,
  style: {
    "color": "#f4952d",
    "margin-left": "5px"
  }
};
const _hoisted_10 = {
  style: {
    "color": "#c1c1c1",
    "margin-top": "0.5rem"
  }
};
const _hoisted_11 = {
  class: "copy"
};
const _hoisted_12 = {
  class: "close2"
};
const _hoisted_13 = {
  id: "copyContentWx",
  class: "copyContentWx"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    id: "postCard",
    class: "postCard",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.handlePostCard && $options.handlePostCard(...args))
  }, [_createElementVNode("img", {
    style: {
      "pointer-events": "none",
      "width": "100%"
    },
    src: $props.content.bgpicture,
    alt: ""
  }, null, 8, _hoisted_1)]), _withDirectives(_createElementVNode("div", _hoisted_2, [_withDirectives(_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", {
    onClick: _cache[1] || (_cache[1] = (...args) => $options.handleClose && $options.handleClose(...args))
  }, "x")]), _createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
    class: "tx",
    style: {
      "width": "70px",
      "height": "70px"
    },
    src: $props.content.avatar3,
    alt: ""
  }, null, 8, _hoisted_6), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString($props.content.dialogTitle3), 1), $props.content.companyName ? (_openBlock(), _createElementBlock("span", _hoisted_9, "@" + _toDisplayString($props.content.companyName), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, _toDisplayString($props.content.dialogViceTitle3), 1)])]), _createElementVNode("div", {
    class: "btn",
    style: _normalizeStyle({
      color: $props.content.dialogBtnTextColor,
      background: $props.content.dialogBtnColor
    }),
    onClick: _cache[2] || (_cache[2] = (...args) => $options.featchScheme && $options.featchScheme(...args))
  }, _toDisplayString($props.content.dialogBtnText), 5)], 512), [[_vShow, $data.dialogType === 'cardPost']]), _withDirectives(_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("span", {
    onClick: _cache[3] || (_cache[3] = (...args) => $options.handleClose && $options.handleClose(...args))
  }, "x")]), _createElementVNode("div", {
    class: "title title2",
    style: _normalizeStyle({
      color: $props.content.dialogTitleColor2
    })
  }, _toDisplayString($props.content.dialogTitle2), 5), _createElementVNode("div", _hoisted_13, _toDisplayString($props.content.copyContentWx), 1), _createElementVNode("div", {
    class: "viceTitle",
    style: _normalizeStyle({
      color: $props.content.dialogViceTitleColor2
    })
  }, _toDisplayString($props.content.dialogViceTitle2), 5)], 512), [[_vShow, $data.dialogType === 'copy']])], 512), [[_vShow, $data.show]])]);
}