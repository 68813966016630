import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1662d32c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 0,
  class: "point"
};
const _hoisted_3 = {
  key: 1,
  class: "block"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 1,
  class: "block"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 3,
  class: "flex",
  style: {
    "margin-top": "10px"
  }
};
const _hoisted_8 = {
  key: 0,
  class: "point"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createElementBlock("div", {
    class: "form_wrap",
    style: _normalizeStyle({
      paddingTop: $props.content.paddingTop + 'px',
      paddingLeft: $props.content.paddingLeft + 'px',
      paddingRight: $props.content.paddingRight + 'px'
    })
  }, [_createVNode(_component_el_form, {
    ref: "formTmpl",
    model: $data.form,
    "label-position": "top",
    rules: $data.rules,
    "hide-required-asterisk": true
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse($props.content.formModel), (formItem, formIndex) => {
      return _openBlock(), _createBlock(_component_el_form_item, {
        key: formIndex,
        prop: formItem.component + formIndex
      }, {
        label: _withCtx(() => [formItem.type !== 'form-button' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [formItem.type !== 'form-input' || formItem.type === 'form-input' && formItem.setStyle.titlePosition === 1 ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [formItem.setStyle.required ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*")) : _createCommentVNode("", true), _createElementVNode("span", {
          class: "title1",
          style: _normalizeStyle({
            fontSize: $props.content.titleFontSize + 'px',
            color: $props.content.titleFontColor,
            opacity: $props.content.titleFontOpacity / 100
          })
        }, _toDisplayString(formItem.setStyle.title), 5), _createElementVNode("span", {
          class: "title2",
          style: _normalizeStyle({
            fontSize: $props.content.viceTitleFontSize + 'px',
            color: $props.content.viceTitleFontColor,
            opacity: $props.content.viceTitleFontOpacity / 100
          })
        }, _toDisplayString(formItem.setStyle.viceTitle), 5)], 64)) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]),
        default: _withCtx(() => [formItem.type === 'form-checkbox' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [$props.content.optionSet === 1 ? (_openBlock(), _createBlock(_component_el_checkbox_group, {
          key: 0,
          modelValue: $data.form[formItem.component + formIndex],
          "onUpdate:modelValue": $event => $data.form[formItem.component + formIndex] = $event,
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            style: {
              "width": "100%"
            }
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formItem.setStyle.optionsSet, col => {
              return _openBlock(), _createBlock(_component_el_col, {
                span: $options.getSpan(formItem.setStyle.col),
                key: col.id
              }, {
                default: _withCtx(() => [(_openBlock(), _createBlock(_component_el_checkbox, {
                  label: col.id,
                  key: col.id
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(col.name), 1)]),
                  _: 2
                }, 1032, ["label"]))]),
                _: 2
              }, 1032, ["span"]);
            }), 128))]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_row, {
          gutter: 24,
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formItem.setStyle.optionsSet, col => {
            return _openBlock(), _createBlock(_component_el_col, {
              span: $options.getSpan(formItem.setStyle.col),
              key: col.id
            }, {
              default: _withCtx(() => [_createElementVNode("div", {
                class: _normalizeClass(["btn_block", {
                  btn_block_active: $data.form[formItem.component + formIndex].includes(col.id)
                }]),
                onClick: $event => $options.handleCheckBoxChange(col, formItem, formIndex)
              }, _toDisplayString(col.name), 11, _hoisted_4)]),
              _: 2
            }, 1032, ["span"]);
          }), 128))]),
          _: 2
        }, 1024)]))], 64)) : _createCommentVNode("", true), formItem.type === 'form-radio' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [$props.content.optionSet === 1 ? (_openBlock(), _createBlock(_component_el_radio_group, {
          key: 0,
          modelValue: $data.form[formItem.component + formIndex],
          "onUpdate:modelValue": $event => $data.form[formItem.component + formIndex] = $event,
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            style: {
              "width": "100%"
            }
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formItem.setStyle.optionsSet, col => {
              return _openBlock(), _createBlock(_component_el_col, {
                span: $options.getSpan(formItem.setStyle.col),
                key: col.id
              }, {
                default: _withCtx(() => [(_openBlock(), _createBlock(_component_el_radio, {
                  label: col.id,
                  key: col.id
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(col.name), 1)]),
                  _: 2
                }, 1032, ["label"]))]),
                _: 2
              }, 1032, ["span"]);
            }), 128))]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_el_row, {
          gutter: 24,
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formItem.setStyle.optionsSet, col => {
            return _openBlock(), _createBlock(_component_el_col, {
              span: $options.getSpan(formItem.setStyle.col),
              key: col.id
            }, {
              default: _withCtx(() => [_createElementVNode("div", {
                class: _normalizeClass(["btn_block", {
                  btn_block_active: $data.form[formItem.component + formIndex] === col.id
                }]),
                onClick: $event => $options.handleRadioChange(col, formItem, formIndex)
              }, _toDisplayString(col.name), 11, _hoisted_6)]),
              _: 2
            }, 1032, ["span"]);
          }), 128))]),
          _: 2
        }, 1024)]))], 64)) : _createCommentVNode("", true), formItem.type === 'form-select' ? (_openBlock(), _createBlock(_component_el_select, {
          key: 2,
          modelValue: $data.form[formItem.component + formIndex],
          "onUpdate:modelValue": $event => $data.form[formItem.component + formIndex] = $event,
          placeholder: `请选择${formItem.setStyle.title}`,
          style: {
            "width": "100%",
            "margin-top": "10px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formItem.setStyle.optionsSet, item => {
            return _openBlock(), _createBlock(_component_el_option, {
              class: "elSelect",
              key: item.id,
              label: item.name,
              value: item.id
            }, null, 8, ["label", "value"]);
          }), 128))]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder"])) : _createCommentVNode("", true), formItem.type === 'form-input' ? (_openBlock(), _createElementBlock("div", _hoisted_7, [formItem.setStyle.required && formItem.setStyle.titlePosition === 2 && formItem.setStyle.isShowAsterisk ? (_openBlock(), _createElementBlock("span", _hoisted_8, "*")) : _createCommentVNode("", true), _createVNode(_component_el_input, {
          modelValue: $data.form[formItem.component + formIndex],
          "onUpdate:modelValue": $event => $data.form[formItem.component + formIndex] = $event,
          size: "large",
          placeholder: `请输入${formItem.setStyle.title}`
        }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])])) : _createCommentVNode("", true), formItem.type === 'form-button' ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: "btn",
          style: _normalizeStyle({
            fontSize: $props.content.btnFontSize + 'px',
            color: $props.content.btnFontColor,
            backgroundColor: $options.getRgba($props.content.btnBgColor, $props.content.btnBgOpacity),
            opacity: $props.content.btnFontOpacity
          }),
          onClick: _cache[0] || (_cache[0] = (...args) => $options.handleSubmit && $options.handleSubmit(...args))
        }, _toDisplayString(formItem.setStyle.btnText), 5)) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["prop"]);
    }), 128))]),
    _: 1
  }, 8, ["model", "rules"])], 4);
}