import { ElAvatar } from 'element-plus';
import { $emit } from '@/utils/gogocodeTransfer';
import { landingPageAnswerDetail } from '@/api/land';
export default {
  name: 'Logic',
  components: {
    ElAvatar
  },
  props: {
    content: Object,
    linkUrl: String
  },
  data() {
    return {
      questionsAndAnswers: [],
      //聊天数据
      toBeAnswers: [],
      //待选项
      step: 0,
      loading: false,
      answerHeight: 200,
      init: false,
      initYin: false,
      shake: false,
      wenIndex: 0
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.landingPageAnswerDetail();
      if (this.content.logic.content.customer_setting.prologue == '' || typeof this.content.logic.content.customer_setting.prologue == 'undefined') {
        setTimeout(() => {
          this.handleChatingData();
        }, 500);
      } else {
        setTimeout(() => {
          this.handleChatingData();
        }, 1500);
      }
    },
    async landingPageAnswerDetail() {
      const {
        data
      } = await landingPageAnswerDetail({
        id: this.content.logic.id
      });
      if (typeof data.content.setStyle == 'undefined') {
        data.content.setStyle = {
          buttomColor: 'rgb(213, 110, 0)',
          // 按钮颜色
          buttomTextColor: 'rgba(255,255,255,1)',
          // 按钮文字颜色
          buttonSize: 14
        };
      }
      this.content.logic = data;
      this.init = true;
      setTimeout(() => {
        this.initYin = true;
      }, 50);
    },
    handleChatingData(item) {
      var that = this;
      if (this.step <= this.content.logic.content.questions_answers.length) {
        if (this.step !== 0) {
          this.questionsAndAnswers.push(item);
        }
        if (this.step < this.content.logic.content.questions_answers.length) {
          if (this.content.logic.content.questions_answers[this.step].questions.length > 1) {
            //一个问题多个子问题的情况
            this.content.logic.content.questions_answers[this.step].questions.map((item, index) => {
              (function (index) {
                setTimeout(function () {
                  that.questionsAndAnswers.push(item);
                  that.wenIndex = that.questionsAndAnswers.length - 1;
                  if (index == that.content.logic.content.questions_answers[that.step].questions.length - 1) {
                    that.toBeAnswers = that.content.logic.content.questions_answers[that.step].answers;
                  }
                }, 1500 * index);
              })(index);
            });
          } else {
            this.questionsAndAnswers.push(...this.content.logic.content.questions_answers[this.step].questions);
            this.toBeAnswers = this.content.logic.content.questions_answers[this.step].answers;
          }
        } else {
          this.toBeAnswers = [];
        }
      }
      this.wenIndex = this.questionsAndAnswers.length - 1;
    },
    handleClickAnswer(item, index) {
      if (this.shake) return;
      this.shake = true;
      setTimeout(() => {
        this.shake = false;
      }, 300);
      this.$emit('linkUrlDel');
      this.step++;
      if (item.is_click === 1) {
        this.wenIndex = index * 1 + 2;
        //有点击动作
        this.questionsAndAnswers.push(item);
        switch (item.click_type) {
          case 1:
            if (item.click_jump_type === 3) {
              // 网页跳转
              $emit(this, 'openUrlLink', item.click_jump_URL);
              return;
            } else if (item.click_jump_type === 2) {
              // 获客助手
              $emit(this, 'jumpCustomerAcquisition', item);
              return;
            } else if (item.click_jump_type == '4') {
              console.log(item);
              $emit(this, 'jumpKf', item.wechatKf);
              return;
            }
            $emit(this, 'openWechat', item.wholeApplet, item.wholeAppletType);
            break;
          case 2:
            this.toBeAnswers = [];
            //this.loading = true;
            setTimeout(() => {
              // this.loading = false;
              this.questionsAndAnswers.push({
                question_content: item.final_content
              });
              console.log(this.questionsAndAnswers);
            }, 1000);
            this.toEnd();
            break;
          case 3:
            const jump = this.content.logic.content.questions_answers.filter(val => {
              return val.id === item.jump_question_answer;
            });
            if (jump.length) {
              this.wenIndex = this.wenIndex * 1 + jump[0].questions.length - 1;
              //this.loading = true;
              setTimeout(() => {
                // this.loading = false;
                this.questionsAndAnswers.push(...jump[0].questions);
                this.toBeAnswers = jump[0].answers;
              }, 1000);
              this.toEnd();
            }
            break;
        }
      } else if (item.is_click === 2) {
        //无点击动作
        // 无反应
      }
      this.toEnd();
    },
    toEnd() {
      //滚动到底部
      this.$nextTick(() => {
        setTimeout(() => {
          this.$emit('scrollBottom');
          // this.answerHeight = this.$refs.answer.clientHeight + 50;
          // let middle = this.$refs['questionContainer'];
          // this.$nextTick(() => {
          //   middle.scrollTop = middle.scrollHeight;
          // });
        }, 0);
      });
    }
  }
};