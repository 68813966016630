import { hexToRgb } from '@/utils';
const inputVaild = {
  1: (rule, value, callback) => {
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
      callback();
    } else {
      callback(new Error('邮箱格式不对'));
    }
  },
  2: (rule, value, callback) => {
    if (Number(value) > 0) {
      callback();
    } else {
      callback(new Error('请输入正确的年龄'));
    }
  },
  3: (rule, value, callback) => {
    if (/^[1-9][0-9]{4,10}$/.test(value)) {
      callback();
    } else {
      callback(new Error('请输入正确的QQ号码'));
    }
  },
  4: (rule, value, callback) => {
    if (/^[a-zA-Z][-_a-zA-Z0-9]{5,19}$/.test(value)) {
      callback();
    } else {
      callback(new Error('请输入正确的微信'));
    }
  },
  5: (rule, value, callback) => {
    if (/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/.test(value)) {
      callback();
    } else {
      callback(new Error('请输入正确的手机号'));
    }
  },
  6: (rule, value, callback) => {
    if (/^[1-9]\d{7}(?:0\d|10|11|12)(?:0[1-9]|[1-2][\d]|30|31)\d{3}$/.test(value)) {
      callback();
    } else {
      callback(new Error('请输入正确的身份证号码'));
    }
  }
};
export default {
  props: ['content', 'hrefs', 'tid', 'text', 'ibrand_id'],
  data() {
    return {
      form: {},
      rules: {}
    };
  },
  created() {
    const forms = JSON.parse(this.content.formModel);
    for (let i = 0; i < forms.length; i++) {
      const item = forms[i];
      if (item.setStyle.required) {
        if (item.component === 'inputTmpl') {
          if (item.setStyle.isValid && item.setStyle.validRule) {
            this.rules[item.component + i] = {
              required: true,
              validator: inputVaild[item.setStyle.validRule],
              trigger: 'blur'
            };
          } else {
            this.rules[item.component + i] = [{
              required: true,
              message: `请填入${item.setStyle.title}`,
              trigger: 'blur'
            }];
          }
        } else {
          this.rules[item.component + i] = [{
            required: true,
            message: `请填入${item.setStyle.title}`,
            trigger: 'change'
          }];
        }
      }
      const MultipleChoice = ['checkboxTmpl'];
      if (item.component !== 'buttonTmpl') {
        if (item.component === 'radioTmpl') {
          this.form[item.component + i] = item.setStyle.radio;
        } else {
          this.form[item.component + i] = MultipleChoice.includes(item.component) ? [] : '';
        }
      }
    }
    this.$nextTick(() => {
      // 动态设置输入框和下拉框的圆角
      const elInputWapper = document.getElementsByClassName('el-input__wrapper');
      console.log(elInputWapper, "elInputWapper");
      Array.from(elInputWapper).forEach(element => {
        element.style.borderRadius = this.content.round + 'px';
        // element.style.boxShadow = '0 0 0 1px #6D3EFF inset!important'
      });
    });

    console.log(this.form, "ad", this.rules);
  },
  computed: {
    getSpan() {
      return key => {
        if (key === 1) {
          return 24;
        }
        if (key === 2) {
          return 12;
        }
        if (key === 3) {
          return 8;
        }
        if (key === 4) {
          return 6;
        }
      };
    }
  },
  methods: {
    getRgba(hex, opacity = 100) {
      if (!hex) {
        return 'none';
      }
      const rgb = hexToRgb(hex);
      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity / 100})`;
    },
    // 单选
    handleRadioChange(col, formItem, formIndex) {
      if (col.id === this.form[formItem.component + formIndex]) {
        return false;
      }
      this.form[formItem.component + formIndex] = col.id;
    },
    // 多选
    handleCheckBoxChange(col, formItem, formIndex) {
      if (this.form[formItem.component + formIndex].includes(col.id)) {
        this.form[formItem.component + formIndex] = this.form[formItem.component + formIndex].filter(item => item !== col.id);
        return false;
      }
      this.form[formItem.component + formIndex].push(col.id);
    },
    // 提交
    handleSubmit() {
      this.$refs.formTmpl.validate(valid => {
        if (valid) {
          this.$emit('closeComPopup');
          if (+this.content.wholeType === 10) {
            alert(this.content.tipText);
          } else if (+this.content.wholeType === 0) {
            window.location.href = this.content.webpageUrl;
          } else if (+this.content.wholeType === 2) {
            // 打开小程序
            this.$emit('openWechat', this.content.appletlink, this.content.appletUrl, this.content.wholeApplet, this.content.wholeAppletType);
          } else if (+this.content.wholeType === 4) {
            // 跳转客服
            this.$emit('jumpKf', this.content.wechatKf);
          } else if (+this.content.wholeType === 6) {
            // 获客助手
            this.$emit('jumpCustomerAcquisition', this.content);
          }
          console.log(this.form, "表单数据");
        } else {
          return false;
        }
      });
    }
  }
};